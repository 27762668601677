import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/home',
        component: () => import('@/views/Home.vue'),
        meta: { requiresAuth: true },
        children: [
            // 主頁
            {
                path: '/dashboard',
                component: () => import('@/views/Dashboard.vue'),
            },
             // 創世神主頁
             {
                path: '/godDashboard',
                component: () => import('@/views/GodDashboard.vue'),
            },
            // 商戶頁面
            {
                path: '/merchants',
                component: () => import('@/views/MerchantList.vue'),
            },
            {
                path: '/merchants/:action',
                component: () => import('@/views/MerchantDetail.vue'),
            },
            {
                path: '/merchants/:action/:id',
                component: () => import('@/views/MerchantDetail.vue'),
            },
            // 分店頁面
            {
                path: '/shops',
                component: () => import('@/views/ShopList.vue'),
            },
            {
                path: '/shops/:action',
                component: () => import('@/views/ShopDetail.vue'),
            },
            {
                path: '/shops/:action/:id',
                component: () => import('@/views/ShopDetail.vue'),
            },
            // 訂單頁面
            {
                path: '/orders',
                component: () => import('@/views/OrderList.vue'),
            },
            {
                path: '/orders/:action',
                component: () => import('@/views/OrderDetail.vue'),
            },
            {
                path: '/orders/:action/:id',
                component: () => import('@/views/OrderDetail.vue'),
            },
            // 客戶頁面
            {
                path: '/customers',
                component: () => import('@/views/CustomerList.vue'),
            },
            {
                path: '/customers/:action',
                component: () => import('@/views/CustomerDetail.vue'),
            },
            {
                path: '/customers/:action/:id',
                component: () => import('@/views/CustomerDetail.vue'),
            },
            // 折扣頁面
            {
                path: '/discounts',
                component: () => import('@/views/DiscountList.vue'),
            },
            {
                path: '/discounts/:action',
                component: () => import('@/views/DiscountDetail.vue'),
            },
            {
                path: '/discounts/:action/:id',
                component: () => import('@/views/DiscountDetail.vue'),
            },
            // 商品分類頁面
            {
                path: '/categories',
                component: () => import('@/views/CategoryList.vue'),
            },
            {
                path: '/categories/:action',
                component: () => import('@/views/CategoryDetail.vue'),
            },
            {
                path: '/categories/:action/:id',
                component: () => import('@/views/CategoryDetail.vue'),
            },
            // 商品頁面
            {
                path: '/products',
                component: () => import('@/views/ProductList.vue'),
            },
            {
				path: '/products/:action',
				component: () => import('@/views/ProductDetail.vue'),
			},
			{
				path: '/products/:action/:id',
				component: () => import('@/views/ProductDetail.vue'),
			},
            // 選項分類頁面
            {
                path: '/extras',
                component: () => import('@/views/ExtraList.vue'),
            },
            {
				path: '/extras/:action',
				component: () => import('@/views/ExtraDetail.vue'),
			},
			{
				path: '/extras/:action/:id',
				component: () => import('@/views/ExtraDetail.vue'),
			},
            // 選項頁面
            {
				path: '/items',
				component: () => import('@/views/ItemList.vue'),
			},
            {
				path: '/items/:action',
				component: () => import('@/views/ItemDetail.vue'),
			},
			{
				path: '/items/:action/:id',
				component: () => import('@/views/ItemDetail.vue'),
			},
            // 開支分類頁面
            {
				path: '/expenseCategories',
				component: () => import('@/views/ExpenseCategoryList.vue'),
			},
            {
				path: '/expenseCategories/:action',
				component: () => import('@/views/ExpenseCategoryDetail.vue'),
			},
			{
				path: '/expenseCategories/:action/:id',
				component: () => import('@/views/ExpenseCategoryDetail.vue'),
			},
            // 開支頁面
            {
				path: '/expenses',
				component: () => import('@/views/ExpenseList.vue'),
			},
            {
				path: '/expenses/:action',
				component: () => import('@/views/ExpenseDetail.vue'),
			},
			{
				path: '/expenses/:action/:id',
				component: () => import('@/views/ExpenseDetail.vue'),
			},
            // 商品報告頁面
            {
				path: '/productsReport',
				component: () => import('@/views/ProductsReport.vue'),
            },
            // 銷售報告頁面
            {
				path: '/salesReport',
				component: () => import('@/views/SalesReport.vue'),
			},
        ],
    },
    {
        path: '*',
        redirect: '/home',
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) return next()
    if ((to.path == '/dashboard' || to.path == '/home') && localStorage.authType === 'admin') return next({ path: '/godDashboard' })
    if ((to.path == '/godDashboard' || to.path == '/home') && localStorage.authType === 'owner') return next({ path: '/dashboard' })
    if (localStorage.isAuth === 'true') return next()
    return next({ path: '/login' })
})

export default router
