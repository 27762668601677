<template>
    <b-container>
        <b-row class="mt-3">
            <b-col>
                <h5>{{ text }}</h5>
                <p v-if="!disabledText">點擊下方項目以編輯{{ text }}</p>
            </b-col>
            <b-col>
                <b-button v-if="!disabled" class="float-right" variant="primary" @click="$router.push(`/${value}/create`)">建立{{ text }}</b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-select class="mt-3" v-if="selectShop" v-model="shopSelected" :options="shopOptions" @change="updateText(shopSelected)"></b-form-select>
            </b-col>
        </b-row>
                 
        <b-row>
            <b-col lg="6" class="my-1">
                <b-form-group label="搜尋" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="searchText" type="search" placeholder="輸入內容"></b-form-input>
                        <b-input-group-append>
                            <b-button :disabled="!searchText" @click="searchText = ''">取消</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        
        <b-row>
            <b-col sm="5" md="6" class="my-1">
                <b-form-group label="每頁顯示數量" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="right" label-size="sm" class="mb-0">
                    <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0"></b-pagination>
            </b-col>
        </b-row>

        <b-table class="mt-3" :sort-by="sortBy" :sort-desc="sortDesc" :fields="fields" :items="itemsBySearch" :current-page="currentPage" :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" hover responsive @row-clicked="onRowClicked" />
    </b-container>
</template>

<script>
export default {
    props: ['value', 'text', 'fields', 'items', 'disabled', 'sortBy', 'sortDesc','disabledText','shop','shopOptions','selectShop'],

    data() {
        return {
            shopSelected:null,
            searchText: '',
            perPage: 10,
            pageOptions: [10, 20, 30, { value: this.items.length, text: '顯示所有' }],
            currentPage: 1,
            filter: null,
            filterOn: [],
            totalRows: 0,
        }
    },

    mounted() {
        this.totalRows = this.items.length
    },

    watch: {
        items: function (val) {
            this.totalRows = val.length
        }
    },
    
    computed: {
        itemsBySearch() {
            if (!this.searchText || this.searchText.length < 1) return this.items
            return this.items.filter(item => {
                if (this.$route.path == '/orders') return String(item.uniNum).includes(this.searchText)
                if (this.$route.path == '/dashboard') return String(item.uniNum).includes(this.searchText)
                return item.name.includes(this.searchText)
            })
        },
    },

    methods: {
        onRowClicked(item) {
            return this.$router.push(`${this.value}/edit/${item.id}`)
        },
        updateText() {
            this.$emit('updateShop', this.shopSelected)
        }
    },
}
</script>
