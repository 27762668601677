<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch('bindCollection', { col: 'merchants' })
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>